import useUserFilterData from "../../../zustand/useUserFilterData";

const MoneyTransactionType = () => {
  const { type, setType } = useUserFilterData();
  const filterList = [
    { id: "all", name: "All" },
    { id: "deposit", name: "Deposit" },
    { id: "withdraw", name: "Withdraw" },
    { id: "refund", name: "Refund" },
  ];

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setType([...type, value]);
    } else {
      setType(type.filter((status) => status !== value));
    }
  };

  return (
    <div className="mt-12">
      <p className="text-[#404040] font-medium font-ibmArabic text-lg mb-4">
        Transaction Type
      </p>
      <div className="grid grid-cols-2 gap-4 whitespace-nowrap">
        {filterList.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <input
              className="text-[#595959] size-4 cursor-pointer"
              type="checkbox"
              id={item.id}
              name="documentStatus"
              value={item.id}
              checked={type.includes(item.id)}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={item.id} // Fixed to 'htmlFor' for JSX
              className="text-[#595959] font-medium font-ibmArabic text-lg"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoneyTransactionType;
