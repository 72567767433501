import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, IconButton, InputBase } from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { debounce } from "../../services/helpers";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import handlebars from "handlebars";
// import path from "./SPOILER_message.html";
// import puppeteer from "puppeteer";

const DeliveryRequestDetails = () => {
  const location = useLocation();
  const item = location.state;

  const generateAndDownloadPDF = () => {
    const htmlContent = `
<!DOCTYPE html>
<html lang="ar" dir="rtl">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Tax Receipt</title>
  <style>
    body {
      font-family: 'IBM Plex Sans Arabic', Arial, sans-serif;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      background-color: #fcfcfc;
      color: #333;
      direction: rtl !important;
    }

    p {
      margin: 0;
      padding: 8px;
    }

    .container {
      max-width: 800px;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;

    }

    .second-container {
      max-width: 800px;
      margin: 10px auto;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;

    }

    .table-wrapper {
      position: relative;
    }

    .break {
      margin: 0 auto;
      width: 80%;
      align-items: center;
      border: none;
      height: 1px;
      background-color: #E6E6E6;
    }

    .pattern {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      opacity: 1;
      z-index: 0;
    }

    .key-pattern {
      position: absolute;
      left: 30%;
      top: 45%;
      transform: translateY(-50%);
      width: 50%;
      height: 70%;
      opacity: 1;
      z-index: 0;
    }

    .table {
      position: relative;
      z-index: 1;
      width: 700px;
      margin: auto;
    }

    .header-logo {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 24px 32px 12px;
    }

    .logo {
      width: 20%;
      margin-right: 0;
    }


    .header-label {
      display: flex;
      font-size: 8px;
      font-weight: 500;
      color: #101010;
      margin-top: 0;
    }

    .details-container {
      width: 90%;
      margin: 0 auto;
      background-color: #FCFCFC;
      border: 1px solid #E6E6E6;
      border-radius: 24px;
    }

    .details {
      padding-top: 24px;
      border-radius: 24px;
      width: 100%;
      margin: 0 auto;
      border-collapse: collapse;
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      padding: 8px 48px;
    }

    .section-header {
      background: #e9c237;
      padding: 8px 24px;
      color: #FFFFFF;
      font-size: 14px;
      text-align: right;
      font-weight: 500;
    }

    .section-header-second {
      background: #e9c237;
      max-width: 88%;
      padding: 8px 24px 8px 24px;
      color: #FFFFFF;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
    }

    .numbers-table {
      width: 100%;
      font-size: 18px;
    }

    .second-table {
      width: 80%;
      margin: 24px 48px;

      border-collapse: collapse;
    }

    .second-table th {
      border: 1px solid #E6E6E6;
      padding: 8px 12px;
      text-align: center;
      width: 50%;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;

    }

    .second-table td {
      padding: 10px;
      text-align: center;
      width: 50%;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
      border: 1px solid #E6E6E6;

    }



    .end-mail {
      text-align: center;
    }

    .thanks-msg {
      font-size: 12px;
      color: #e9c237;
      font-weight: 400;
    }

    .sabika-team {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }

    .QR {
      width: 10%;
      height: 10%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 4px 2px 4px 4px;
    }

    .first-footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
    }


    .first-footer a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: #333;
      font-size: 16px;
      font-weight: 600;
    }

    .icon {
      width: 7px;
      margin-right: 3px;
    }

    .contact {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-size: 6px;
      font-weight: bold;
      gap: 8px;
      margin: 8px;
    }

    .receipt-data {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .labelOne {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .client-data {
      padding-top: .75rem;

    }

    .section-title {
      width: 15%;
      padding-bottom: 2px;
      font-size: .75rem;
      line-height: 14.14px;
      color: #333333;
      border-bottom: 1px solid #333;
      border-width: 20;
    }

    @media (max-width: 768px) {
      .container {
        width: 95%;
      }

      .pattern {
        width: 80px;
        left: -30px;
      }

      .details {
        padding: 8px;
      }
    }
  </style>
</head>

<body>
  <div class="container">
    <div class="table-wrapper">
       <img src="/left pattern of keys.svg" alt="" class="pattern" /> 
      <div class="header-logo">
        <img src="/logo.svg" alt="Sabika Logo" class="logo" style={{   width: 20%;
      margin-right: 0;}}/>
        <div>
          <div class="header-label"><span>سجل تجاري :</span>238797</div>
          <div class="header-label"><span>الرقم الضريبي:</span>764-894-226</div>
        </div>
      </div>
      <div class="table">

        <div class="details-container">
          <div class="details">
            <div class="label"><span>اسم العميل:</span> <span>${item?.national_name}</span></div>
            <div class="label"><span>رقم القومي:</span> <span>${item?.nationalId}</span></div>
            <div class="label"><span>تليفون:</span> <span>${item?.phone}</span></div>
            <div class="label"><span>إيميل:</span> <span>mail</span></div>
            <div class="label"><span>رقم الفاتورة:</span> <span>number</span></div>
            <div class="label"><span>تاريخ الفاتورة:</span> <span>date</span></div>
          </div>

          <div class="section-header">تفاصيل الطلب :</div>

          <div class="details">
            <div class="label"><span>الكمية المطلوبة:</span> <span>0 جرام</span></div>
            <div class="label"><span>إجمالي التكلفة:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>رسوم المصنعية:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>الضرائب:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>رسوم المعاملة:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>رسوم التخزين:</span> <span>0,00.00 ج</span></div>
            <hr class="break" />
            <div class="label"><span>الإجمالي:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>الخصم:</span> <span>0,00.00 ج</span></div>
            <div class="label"><span>إجمالي المبلغ المدفوع:</span> <span>0,00.00 ج</span></div>
          </div>

          <div class="section-header">الأرقام المتسلسلة :</div>

          <div class="numbers-table">
            <table class="second-table">
              <tr>
                <th class="">الوزن</th>
                <th class="">الأرقام المتسلسلة</th>
              </tr>
              <tr>
                <td class="">0 جرام</td>
                <td class="">0000000</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="end-mail">
          <p class="thanks-msg">شكرًا لاختيارك سبيكة نحن نقدر ثقتك ونتطلع لخدمتك مرة أخرى <br><span
              style="color: #000000;">مع تحيات، فريق سبيكة</span></p>
        </div>
      </div>
    </div>

    <div class="first-footer" dir="ltr">
      <img src="/qr 1.svg" alt="" class="QR">
      <div class="contact">
        <div> <span href=""><img src="./call.svg" alt="" class="icon">01040031642</span>
        </div>
        <div> <span href=""><img src="./sms.svg" alt="" class="icon">contact@sabika.app</span>
        </div>
        <div> <span href=""><img src="./global.svg" alt="" class="icon">www.sabika.app</span>
        </div>
        <div> <span href=""><img src="./location.svg" alt="" class="icon">Golden Gate Tower, 5, Maadi, Cairo</sp>
        </div>
      </div>
    </div>
  </div>
  <div class="second-container">
    <img src="./left pattern of keys.svg" alt="" class="pattern" />
    <div class="header-logo">
      <img src="./logo.svg" alt="Sabika Logo" class="logo" />
      <div>
        <div class="header-label" style="margin-left: 12px;"><span>سجل تجاري :</span> 238797</div>
        <div class="header-label" style="margin-left: 12px;"><span>الرقم الضريبي:</span> 764-894-226</div>
      </div>
    </div>
    <div class="table">
      <img src="./middel paper key.svg" alt="" class="key-pattern">
      <div class="section-header-second">نموذج إقرار استلام سبيكة ذهب</div>
      <div class="client-data">
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span>تاريخ التسليم:
              02/01/2025</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span>رقم الإيصال:
              0000000A</span></div>
        </div>
      </div>


      <div class="client-data">
        <h3 class="section-title">بيانات العميل:</h3>
        <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>الاسم الكامل:</span></div>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>رقم الهاتف:</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>رقم البطاقة
              الشخصية:</span></div>
        </div>

      </div>
      <div class="client-data">
        <h3 class="section-title">بيانات السبيكة:</h3>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>رقم السبيكة (Serial
              number):</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>الوزن : 0 جرام</span>
          </div>
        </div>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>نسبة النقاء:
              999.9</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>إجمالي المبلغ المدفوع:
              0,00.0 ج</span></div>
        </div>

      </div>
      <div class="client-data">
        <h3 class="section-title">إقرار العميل:</h3>
        <p style="font-size: 12px;">أقر أنا المذكور أعلاه أنني قد استلمت السبيكة الذهب الموضحة أعلاه بحالتها كما هو موضح
          في تفاصيل الفاتورة الخاصة بالشراء، وقد قمت بمراجعة السبيكة والتأ كد من بياناتها المطابقة لما ورد في الطلب. كما
          أقر أنني أتحمل المسؤولية الكاملة عن السبيكة بعد استلامها, وليس لي الحق في مطالبة شركة سبيكة بأي مستحقات او
          متعلقات تخص وزن السبيكة المستلمة او ما يعادل وزنها على المنصة</p>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span> اسم العميل:
              {{name}}</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span>التوقيع:{{name}}</span>
          </div>
        </div>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span> التاريخ: {{name}}</span>
          </div>
        </div>


      </div>
      <div class="client-data">
        <h3 class="section-title">بيانات الشركة:</h3>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>عنوان الشركة: برج جولدن
              تاور-5-المعادي-القاهرة</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>رقم الهاتف :
              01040031642</span></div>
        </div>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>البريد
              الإلكتروني:contact@sabika.app</span></div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>الموقع
              الإلكتروني:www.sabika.app</span></div>
        </div>

      </div>
      <div class="client-data" style="padding-bottom: 50px;">
        <h3 class="section-title">توقيع مسؤول التسليم:</h3>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span>اسم مسؤول التسليم: باسم
              مبروك أبو الريش</span></div>
        </div>
        <div class="" style="display: flex; align-items: center; ">
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;"><span>التوقيع:{{name}}</span>
          </div>
          <div style="width: 50%; font-size: 12px; line-height: 18px; padding-top:8px;">• <span>التاريخ:{{name}}</span>
          </div>
        </div>

      </div>

      <div class="first-footer" dir="ltr">
        <img src="./qr 1.svg" alt="" class="QR" style="margin-left: -45px;">
        <div class="contact">
          <div> <span href=""><img src="./call.svg" alt="" class="icon">01040031642</span>
          </div>
          <div> <span href=""><img src="./sms.svg" alt="" class="icon">contact@sabika.app</span>
          </div>
          <div> <span href=""><img src="./global.svg" alt="" class="icon">www.sabika.app</span>
          </div>
          <div> <span href=""><img src="./location.svg" alt="" class="icon">Golden Gate Tower, 5, Maadi, Cairo</sp>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
    `;

    html2pdf().from(htmlContent).save("download.pdf");
  };
  // async function htmlToPdfMail() {
  //   try {
  //     const htmlEmail = handlebars.compile(htmlContent);

  //     const html = htmlEmail({});

  //     const browser = await puppeteer.launch({
  //       headless: true,
  //     });
  //     const page = await browser.newPage();
  //     await page.setContent(html);
  //     await page.pdf({
  //       // path: __dirname + "\\receipt.pdf",
  //       printBackground: true,
  //       format: "A4",
  //     });
  //     await browser.close();
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/NotReceivedRequest/${item.id}`, { state: item });
  };

  const [htmlContent, setHtmlContent] = useState("");
  const [shown, setIsShown] = useState("");
  const receiptRef = useRef(null);

  // const dynamicData = {
  //   name: item?.national_name,
  //   nationalId: item?.nationalId,
  //   phone: "0" + item?.phone,
  // };

  // const replacePlaceholders = (html) => {
  //   return html
  //     .replace("{{name}}", dynamicData.name)
  //     .replace("{{nationalId}}", dynamicData.nationalId)
  //     .replace("{{phone}}", dynamicData.phone);
  // };

  useEffect(() => {
    fetch("/SPOILER_message.html")
      .then((response) => response.text())
      // .then((data) => {
      //   setHtmlContent(data)
      // })
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error loading HTML file:", error));
  }, []);

  const generatePDF = async () => {
    const element = receiptRef.current;
    if (!element) return;

    element.classList.remove("hidden"); // Ensure visibility

    await new Promise((resolve) => {
      const images = element.getElementsByTagName("img");
      let loadedCount = 0;

      if (images.length === 0) {
        resolve();
        return;
      }

      Array.from(images).forEach((img) => {
        if (img.complete) {
          loadedCount++;
        } else {
          img.onload = () => {
            loadedCount++;
            if (loadedCount === images.length) resolve();
          };
        }
      });

      if (loadedCount === images.length) resolve();
    });
    // A4 dimensions in mm
    const a4Width = 210; // A4 width in mm
    const a4Height = 297; // A4 height in mm

    // Convert A4 dimensions to pixels (assuming 96 DPI)
    const pxWidth = (a4Width * 96) / 25.4; // Convert mm to pixels
    const pxHeight = (a4Height * 96) / 25.4; // Convert mm to pixels

    // Temporarily adjust the HTML element's dimensions for PDF generation
    const originalWidth = element.offsetWidth;
    const originalHeight = element.offsetHeight;
    element.style.width = `${pxWidth}px`; // Set width to A4 width in pixels
    element.style.height = "auto"; // Allow height to adjust automatically

    // Generate the canvas with the adjusted dimensions
    const canvas = await html2canvas(element, {
      scale: 3, // Increase scale for better quality
      width: pxWidth, // Set canvas width to A4 width
      height: element.scrollHeight, // Use the actual height of the content
      useCORS: true, // Enable CORS for external resources
      logging: true, // Enable logging for debugging
    });

    const imgData = canvas.toDataURL("image/png", 1.0);

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate the aspect ratio of the canvas
    const aspectRatio = canvas.width / canvas.height;

    // Calculate the height of the image in the PDF
    let imgHeight = (canvas.height * pdfWidth) / canvas.width;

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);

    // Check if the content exceeds the height of a single page
    if (imgHeight > pdfHeight) {
      let position = 0; // Track the position of the content
      let remainingHeight = imgHeight;

      while (remainingHeight > 0) {
        pdf.addPage(); // Add a new page
        position -= pdfHeight; // Move the position up
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight); // Add the image to the new page
        remainingHeight -= pdfHeight; // Reduce the remaining height
      }
    }

    pdf.save("download.pdf");

    // Restore the original dimensions of the HTML element
    element.style.width = `${originalWidth}px`;
    element.style.height = `${originalHeight}px`;
    element.classList.add("hidden"); // Hide after generation
  };
  const handleVerifyNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/VerifyId/${item.id}`, { state: item });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("ar-EG", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      numberingSystem: "latn",
    }).format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      numberingSystem: "latn",
    }).format(date);
  };
  return (
    <div className="w-full h-full pb-[8%]" dir="ltr">
      <DeliveryMainHeader
        label={"تفاصيل الطلب"}
        back={true}
        onClick={() => navigate(-1)}
      />

      <main className="w-full" dir={"rtl"}>
        <div className="w-full flex items-center flex-col justify-center mt-4">
          <p className="text-[#B3B3B3] text-xs font-normal font-ibmArabic flex self-start ms-[6%]  mt-[20%]">
            تفاصيل العميل
          </p>
          <div
            className="flex  w-[90%] rounded-xl bg-[#FFF] px-4 py-8 mt-4"
            style={{
              boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
            }}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between w-full">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  اسم العميل :
                </p>
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  {item?.national_name}
                </p>
              </div>
              <div className="mt-4  flex items-center justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  رقم الهاتف :
                </p>
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  {"0" + item?.phone}
                </p>
              </div>
              <div className="mt-4  flex items-center justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  الرقم القومي :
                </p>
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  {item?.nationalId}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center flex-col justify-center mt-6">
          <p className="text-[#B3B3B3] text-xs font-normal font-ibmArabic flex self-start ms-[6%] ">
            تفاصيل التسليم
          </p>
          <div
            className="flex  w-[90%] rounded-xl bg-[#FFF] px-4 py-8 mt-4"
            style={{
              boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
            }}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between w-full">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  تاريخ التسليم :
                </p>
                {item?.receive_time && (
                  <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                    {formatDate(item?.receive_time)}
                  </p>
                )}
              </div>
              <div className="mt-4  flex items-center justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  وقت التسليم :
                </p>
                {item?.receive_time && (
                  <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                    {formatTime(item?.receive_time)}
                  </p>
                )}
              </div>
              <div className="mt-4 flex flex-col  justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  العنوان :
                </p>
                <p className="text-[#333333] text-sm font-normal text-end font-ibmArabic mt-2">
                  {item?.receive_location}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center flex-col justify-center mt-6">
          <p className="text-[#B3B3B3] text-xs font-normal font-ibmArabic flex self-start ms-[6%] ">
            تفاصيل الطلب
          </p>
          <div
            className="flex  w-[90%] rounded-xl bg-[#FFF] px-4 py-8 mt-4"
            style={{
              boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
            }}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between w-full">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  وزن السبيكة :
                </p>
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  {Number(item?.weight) + " جرام"}
                </p>
              </div>
              <div className="mt-4  flex items-center justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  العدد :
                </p>
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  {item?.ingot_count}
                </p>
              </div>
              <div className="h-[1px] w-[100%] flex items-center bg-[#B3B3B3] my-4"></div>
              <div className="flex flex-col  justify-between w-full ">
                <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                  الأرقام المتسلسلة :
                </p>
                {item?.goldTransactions?.map((transaction) => (
                  <p
                    key={transaction?.id}
                    className="text-[#333333] text-sm font-normal text-start font-ibmArabic mt-2"
                  >
                    {transaction?.receive_serials}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
          <Button
            // onClick={generateAndDownloadPDF}
            onClick={() => {
              handleVerifyNavigation(item);
            }}
            sx={{
              "&:hover": { backgroundColor: "#917244" },
              backgroundColor: "#917244",
              width: "90%",
              height: "48px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "16px",
              marginTop: "5%",
              fontFamily: "ibmArabic",
            }}
          >
            تنزيل الملفات للطباعه و المتابعه
          </Button>
          <Button
            onClick={() => {
              handleNavigation(item);
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              backgroundColor: "#ffffff",
              width: "90%",
              height: "48px",
              borderRadius: "12px",
              color: "#917244",
              border: "2px solid #917244",
              fontSize: "16px",
              marginTop: "5%",
              fontFamily: "ibmArabic",
            }}
          >
            لم يتم التسليم
          </Button>
          {/* <div
            ref={receiptRef}
            id="html-content"
            dangerouslySetInnerHTML={{
              __html: replacePlaceholders(htmlContent),
            }}
            className={"hidden"}
          /> */}
        </div>
      </main>
    </div>
  );
};

export default DeliveryRequestDetails;
