import React, { useState } from "react";
import { Drawer } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import closeCircle from "../../../svg/new-close-circle.svg";
import MoneyDateFilter from "./MoneyDateFilter";
import useUserFilterData from "../../../zustand/useUserFilterData";
import MoneyTransactionStatus from "./MoneyTransactionStatus";
import MoneyTransactionType from "./MoneyTransactionType";
const MoneyTransactionDrawer = ({
  data,
  setData,
  setSearchParams,
  queryParameters,
}) => {
  const {
    date_from,
    date_to,
    setDateFrom,
    setDateTo,
    setStatus,
    status,
    type,
    setType,
  } = useUserFilterData();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };
  const getStatusFilter = (status) => {
    if (status.includes("all")) return [1, 2, 3];

    const statusMap = {
      pending: 1,
      approved: 2,
      rejected: 3,
    };

    return status.map((s) => statusMap[s]).filter(Boolean); // Remove undefined values
  };
  const getTypeFilter = (type) => {
    if (type.includes("all")) return [1, 2, 5];

    const typeMap = {
      deposit: 1,
      withdraw: 2,
      refund: 5,
    };

    return type.map((t) => typeMap[t]).filter(Boolean); // Remove undefined values
  };

  const handleFilter = () => {
    setData((prevData) => ({
      ...prevData,
      page: 0,
      date_to: date_to,
      date_from: date_from,
      status: status.length > 0 ? getStatusFilter(status) : prevData.status, // Ensure status is preserved
      type: type.length > 0 ? getTypeFilter(type) : prevData.type, // Ensure type is preserved
    }));

    setSearchParams({
      date_from,
      date_to,
      status:
        status.length > 0 ? status.join(",") : queryParameters.status.join(","), // Prevents resetting to empty
      type: type.length > 0 ? type.join(",") : queryParameters.type.join(","), // Prevents resetting to empty
    });

    setOpen(false);
  };

  return (
    <div>
      <IoFilterOutline
        size={25}
        style={{ cursor: "pointer" }}
        onClick={toggleDrawer(true)} // Open the drawer when clicked
      />
      <Drawer
        anchor="right" // Open drawer from the right
        open={open}
        onClose={toggleDrawer(false)} // Close the drawer when needed
      >
        <div className="w-96 p-8">
          <img
            src={closeCircle}
            alt="close"
            className="mb-8 cursor-pointer"
            onClick={toggleDrawer(false)}
          />
          {/* //? <--------------Date-------------> */}
          <MoneyDateFilter />
          {/* //! MoneyTransactionStatus */}
          <MoneyTransactionStatus />

          {/* MoneyTranactionType  */}
          <MoneyTransactionType />

          {/* //? Filter and Reset Button */}
          <div className="flex items-center gap-2 justify-center mt-10">
            <button
              onClick={handleFilter}
              className="text-xs font-medium px-16 py-3 font-ibmArabic bg-[#917244] rounded-xl text-white"
            >
              Filter
            </button>
            <button
              onClick={() => {
                setDateFrom("");
                setDateTo("");
                setStatus([]);
                setType([1, 2, 5]);
                setData({
                  ...data,
                  date_from: null,
                  date_to: null,
                  orderBy: null,
                  sort: null,
                  page: 0,
                  limit: 10,
                  status: [],
                  type: [1, 2, 5],
                });
              }}
              className="text-xs font-medium px-16 py-3 font-ibmArabic   border border-[#917244] rounded-xl text-[#917244]"
            >
              Reset
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MoneyTransactionDrawer;
